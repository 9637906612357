export const ValidateManagerWorkApplicationForm = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError,
  isLongHaul,
  isCrystalSki
) => {
  // console.log(managerWorkApplication);
  console.log(managerWorkApplicationError);
  console.log(isCrystalSki);

  if (isCrystalSki) {
    return ManagerCrystalSkiFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else if (isLongHaul) {
    return ManagerLongHaulFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else {
    return ManagerFormValidation(managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError);
  }
};

const ManagerFormValidation = (managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError) => {
  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedWinterAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedWinterAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedWinterAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedSummerAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedSummerAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedSummerAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};

const ManagerLongHaulFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerLongHaulForm.isManagerSupportApplication) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerSupportApplication: 'Please select option',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerLongHaulForm.comments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        comments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};

const ManagerCrystalSkiFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerCrystalSkiForm?.suitableForBeachProgramme) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCrystalSkiForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        suitableForBeachProgramme: 'Please select if suitable for beach programme',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerCrystalSkiForm?.comments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCrystalSkiForm: {
        ...managerWorkApplicationError.comments,
        comments: 'Please add comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  return true;
};
