import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
// import Select from 'react-select';

// import {
//   if_collegue_working_concept,
//   manager_colleague_strenghts_options,
//   manager_development_areas_options,
// } from 'Constants/selectOptionsConstants';

const ManagerCommentsForm = (props) => {
  const {
    managerWorkApplication,
    setManagerWorkApplication,
    managerWorkApplicationError,
    setManagerWorkApplicationError,
    isManagerWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '0.5%',
  };
  return (
    <Card style={cardStyle}>
      <Card.Header>
        <i className="fa fa-comments-o mr-2" />
        Manager questionary
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Do you validate/support the winter availability, winter destination and winter job family requests plus
                the submitted language skills? {requiredField()}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedWinterAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedWinterAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm?.isManagerValidatedWinterAvailability === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedWinterAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedWinterAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />

                  {managerWorkApplicationError.managerCommentsForm.isManagerValidatedWinterAvailability && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isManagerValidatedWinterAvailability}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              as={Col}
              hidden={managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability !== 'No'}
            >
              <Form.Label>Winter comments {requiredField()} </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerValidatedWinterAvailabilityComments: e.target.value,
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerValidatedWinterAvailabilityComments: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={managerWorkApplication.managerCommentsForm.managerValidatedWinterAvailabilityComments || ''}
                disabled={isManagerWorkApplicationSubmitted}
              />

              {managerWorkApplicationError.managerCommentsForm.managerValidatedWinterAvailabilityComments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerValidatedWinterAvailabilityComments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Do you validate/support the summer availability, summer destination and summer job family requests plus
                the submitted language skills? {requiredField()}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedSummerAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedSummerAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedSummerAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedSummerAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCommentsForm.isManagerValidatedSummerAvailability && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isManagerValidatedSummerAvailability}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              as={Col}
              hidden={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability !== 'No'}
            >
              <Form.Label>Summer comments {requiredField()}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerValidatedSummerAvailabilityComments: e.target.value,
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerValidatedSummerAvailabilityComments: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={managerWorkApplication.managerCommentsForm.managerValidatedSummerAvailabilityComments || ''}
                disabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.managerValidatedSummerAvailabilityComments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerValidatedSummerAvailabilityComments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ManagerCommentsForm;
