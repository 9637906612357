import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
// import Select from 'react-select';

// import {
//   if_collegue_working_concept,
//   manager_colleague_strenghts_options,
//   manager_development_areas_options,
// } from 'Constants/selectOptionsConstants';

const ManagerCrystalSkyCommentsForm = (props) => {
  const {
    managerWorkApplication,
    setManagerWorkApplication,
    managerWorkApplicationError,
    setManagerWorkApplicationError,
    isManagerWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '0.5%',
  };
  return (
    <Card style={cardStyle}>
      <Card.Header>
        <i className="fa fa-comments-o mr-2" />
        Manager questionary
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Are they suitable for the Beach Programme? {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value={true}
                    onChange={() => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCrystalSkiForm: {
                          ...managerWorkApplication.managerCrystalSkiForm,
                          suitableForBeachProgramme: 'Yes',
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        ...managerWorkApplicationError,
                        managerCrystalSkiForm: {
                          suitableForBeachProgramme: null,
                        },
                      });
                    }}
                    checked={managerWorkApplication.managerCrystalSkiForm?.suitableForBeachProgramme == 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value={false}
                    onChange={() => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCrystalSkiForm: {
                          ...managerWorkApplication.managerCrystalSkiForm,
                          suitableForBeachProgramme: 'No',
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        ...managerWorkApplicationError,
                        managerCrystalSkiForm: {
                          suitableForBeachProgramme: null,
                        },
                      });
                    }}
                    checked={managerWorkApplication.managerCrystalSkiForm?.suitableForBeachProgramme == 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCrystalSkiForm?.suitableForBeachProgramme && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCrystalSkiForm?.suitableForBeachProgramme}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Comments {requiredField()}</Form.Label>
              <Form.Control
                as="textarea"
                minLength={100}
                maxLength={500}
                rows={8}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCrystalSkiForm: {
                      ...managerWorkApplication.managerCrystalSkiForm,
                      comments: e.target.value,
                    },
                  });
                  setManagerWorkApplicationError({
                    ...managerWorkApplicationError,
                    managerCrystalSkiForm: {
                      comments: null,
                    },
                  });
                }}
                value={managerWorkApplication.managerCrystalSkiForm?.comments}
                disabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCrystalSkiForm?.comments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCrystalSkiForm?.comments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ManagerCrystalSkyCommentsForm;
