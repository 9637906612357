import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB } from 'Constants/dateFormatConstants';
import RepBeachForm from 'Components/WorkApplicationForms/RepBeachForm';
import RepSummerForm from 'Components/WorkApplicationForms/RepSummerForm';
import RepWinterForm from 'Components/WorkApplicationForms/RepWinterForm';
import RepLongHaulForm from 'Components/WorkApplicationForms/RepLongHaulForm';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { RepFormValidation } from 'Components/WorkApplicationForms/FormValidation/RepFormValidation';
import RepCrystalSkiForm from 'Components/WorkApplicationForms/RepCrystalSkiForm';

const RepWorkApplicationView = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.currentUser.email);
  const tppSettings = useSelector((state) => state.settings);

  const jobFamiliesOptionsList = useSelector((state) => state.masterData.jobFamilies);
  const jobTitlesOptionsList = useSelector((state) => state.masterData.jobTitles);
  const jobFamiliesWork = useSelector((state) => state.settings.jobFamiliesWork);
  // const destinationsWork = useSelector((state) => state.settings.acceptDeclineCountry);

  const [assigmentsList, setAssigmentsList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isCrystalSki, setIsCrystalSki] = useState(false);
  const [repWorkApplication, setRepWorkApplication] = useState({
    repGlobalForm: {
      signature: '',
      location: '',
      repComments: '',
      managerName: '',
    },
    repSummerForm: {
      preferencesFor: '',
      preferenceStartMonth: '',
      preferenceEndMonth: '',

      firstChoiceDestination: '',
      firstChoiceJobTitle: '',
      firstChoiceJobFamily: '',

      secondChoiceDestination: '',
      secondChoiceJobTitle: '',
      secondChoiceJobFamily: '',

      thirdChoiceDestination: '',
      thirdChoiceJobTitle: '',
      thirdChoiceJobFamily: '',

      fourthChoiceDestination: '',
      fourthChoiceJobTitle: '',
      fourthChoiceJobFamily: '',

      mostImportant: '',
      accelerateDevelopmentProgram: '',
      willingCompleteVisaApplication: '',
      fullPartnerName: '',
      considerChangeRole: '',
      considerChangeRoleJobTitles: '',
      expiringVisaDate: '',
      customerNationalities: [],
    },
    repWinterForm: {
      preferencesFor: '',
      preferenceStartMonth: '',
      preferenceEndMonth: '',

      firstChoiceDestination: '',
      firstChoiceJobTitle: '',
      firstChoiceJobFamily: '',

      secondChoiceDestination: '',
      secondChoiceJobTitle: '',
      secondChoiceJobFamily: '',

      thirdChoiceDestination: '',
      thirdChoiceJobTitle: '',
      thirdChoiceJobFamily: '',

      fourthChoiceDestination: '',
      fourthChoiceJobTitle: '',
      fourthChoiceJobFamily: '',

      mostImportant: '',
      accelerateDevelopmentProgram: '',
      willingCompleteVisaApplication: '',
      fullPartnerName: '',
      considerChangeRole: '',
      considerChangeRoleJobTitles: '',
      expiringVisaDate: '',
      customerNationalities: [],
    },
    repLongHaulForm: {
      confortableSourceMarket: [],
      passportExpiry: '',
      currentJobFamily: '',
      currentJobTitle: '',
      guestType: [],
      internationalConcept: [],
      nationalConcept: [],
      isWillingWorkOneYear: false,
      isWorkedInLongHaul: false,
      isWorkedInLongHaulYes: '',
      destinationOne: '',
      destinationTwo: '',
      destinationThree: '',
      destinationFour: '',
      whyYouSuitable: '',
    },
    repBeachForm: {
      currentJobTitle: '',
      currentJobFamily: '',
      guestType: [],
      internationalConcept: [],
      nationalConcept: [],
      accelerateDevelopmentProgram: '',
    },
    repCrystalSkiForm: {
      summer2025Placement: null, // Yes or No
      interestedInPlacement: null, // Yes or No
      roleInterested: null, // Hotel Rep, Airport Rep, etc.
      availableFrom: null, // Date in YYYY-MM format
      availableTo: null, // Date in YYYY-MM format
      interestReason: '', // Text up to 500 characters
      teamManager: null, // Selected manager name
    },
  });
  const [repWorkApplicationError, setRepWorkApplicationError] = useState({
    repGlobalForm: {
      staffId: '',
      currentSeason: '',
      nextSeason: '',
      signature: '',
      location: '',
      managerName: '',
    },
    repSummerForm: {
      preferencesFor: '',
      preferenceStartMonth: '',
      preferenceEndMonth: '',

      firstChoiceDestination: '',
      firstChoiceJobTitle: '',
      firstChoiceJobFamily: '',

      secondChoiceDestination: '',
      secondChoiceJobTitle: '',
      secondChoiceJobFamily: '',

      thirdChoiceDestination: '',
      thirdChoiceJobTitle: '',
      thirdChoiceJobFamily: '',

      fourthChoiceDestination: '',
      fourthChoiceJobTitle: '',
      fourthChoiceJobFamily: '',

      mostImportant: '',
      accelerateDevelopmentProgram: '',
      willingCompleteVisaApplication: '',
      fullPartnerName: '',
      considerChangeRole: '',
      considerChangeRoleJobTitles: '',
      expiringVisaDate: '',
      customerNationalities: '',
    },
    repWinterForm: {
      preferencesFor: '',
      preferenceStartMonth: '',
      preferenceEndMonth: '',

      firstChoiceDestination: '',
      firstChoiceJobTitle: '',
      firstChoiceJobFamily: '',

      secondChoiceDestination: '',
      secondChoiceJobTitle: '',
      secondChoiceJobFamily: '',

      thirdChoiceDestination: '',
      thirdChoiceJobTitle: '',
      thirdChoiceJobFamily: '',

      fourthChoiceDestination: '',
      fourthChoiceJobTitle: '',
      fourthChoiceJobFamily: '',

      mostImportant: '',
      accelerateDevelopmentProgram: '',
      willingCompleteVisaApplication: '',
      fullPartnerName: '',
      considerChangeRole: '',
      considerChangeRoleJobTitles: '',
      expiringVisaDate: '',
      customerNationalities: '',
    },
    repLongHaulForm: {
      confortableSourceMarket: '',
      passportExpiry: '',
      currentJobFamily: '',
      currentJobTitle: '',
      guestType: '',
      internationalConcept: '',
      nationalConcept: '',
      isWillingWorkOneYear: false,
      isWorkedInLongHaul: false,
      isWorkedInLongHaulYes: '',
      destinationOne: '',
      destinationTwo: '',
      destinationThree: '',
      destinationFour: '',
      whyYouSuitable: '',
    },
    repBeachForm: {
      currentJobTitle: '',
      currentJobFamily: '',
      guestType: [],
      internationalConcept: [],
      nationalConcept: [],
      accelerateDevelopmentProgram: '',
    },
    repCrystalSkiForm: {
      summer2025Placement: null, // Yes or No
      interestedInPlacement: null, // Yes or No
      roleInterested: null, // Hotel Rep, Airport Rep, etc.
      availableFrom: null, // Date in YYYY-MM format
      availableTo: null, // Date in YYYY-MM format
      interestReason: '', // Text up to 500 characters
      teamManager: null, // Selected manager name
    },
  });
  const [isWorkApplicationSubmitted, setIsWorkApplicationSubmitted] = useState(false);

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };
  const [refreshParentComponent, setRefreshParentComponent] = useState(1);
  const [managersList, setManagerList] = useState([]);

  // const jobFamiliesOptionsList = useSelector((state) => state.masterData.jobFamilies);
  // const jobTitlesOptionsList = useSelector((state) => state.masterData.jobTitles);
  const countriesOptionsList = useSelector((state) => state.masterData.countries);

  useEffect(() => {
    if (!currentUserEmail) return;
    RestClient.Get(`staff/getbyemail/${currentUserEmail}`).then((staffInfo) => {
      // console.log(staffInfo);
      if (!staffInfo) return;

      setEmployee(staffInfo);
    });
  }, [currentUserEmail]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;
    RestClient.Get(`positionAssign/byStaffId/${employee.staffID}`).then((response) => {
      // console.log(response);
      if (!response || !response.positionAssignList) return;

      // check the tpp configuration in order to find what jFamilies are allowed
      // to apply and also see this applications
      let filteredAssignments = response.positionAssignList;

      if (jobFamiliesWork !== '') {
        filteredAssignments = filteredAssignments.filter((assign) => {
          return jobFamiliesWork.split(',').some((element) => element.toLowerCase() === assign.jobFamily.toLowerCase());
        });

        // set if is crystal ski or not in order to show the correct form and set a variable with boolean
        var validJobFamilies = tppSettings.jobFamiliesWork
          .split(',')
          .map((jobFamily) => jobFamily.trim().toLowerCase());
        setIsCrystalSki(validJobFamilies.some((assign) => assign.toLowerCase().includes('crystal ski')));
      }

      // if (destinationsWork !== '') {
      //   filteredAssignments = filteredAssignments.filter((assign) => {
      //     return destinationsWork
      //       .split(',')
      //       .some((element) => element.toLowerCase() === assign.destination.toLowerCase());
      //   });
      // }

      setAssigmentsList(filteredAssignments);
    });
  }, [employee, refreshParentComponent]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;

    RestClient.Get(`workApplication/rep/${employee.staffID}/${tppSettings.curSeason}/${isCrystalSki}`)
      .then((response) => {
        if (response.status && !response.repWorkApplication) {
          setRepWorkApplication({
            repGlobalForm: {},
            repSummerForm: {},
            repWinterForm: {},
            repLongHaulForm: {},
            repBeachForm: {},
            repCrystalSkiForm: {},
          });
        } else if (
          response.status &&
          response.repWorkApplication &&
          ((response.repSummerForm && response.repWinterForm) || response.repLongHaulForm || response.repCrystalSkiForm)
        ) {
          toastr.success('Success', response.message);
          setRepWorkApplication({
            repGlobalForm: response.repWorkApplication,
            repSummerForm: response.repSummerForm,
            repWinterForm: response.repWinterForm,
            repLongHaulForm: response.repLongHaulForm,
            repBeachForm: response.repBeachForm,
            repCrystalSkiForm: response.repCrystalSkiForm,
          });
          setIsWorkApplicationSubmitted(true);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [employee, refreshParentComponent, isCrystalSki]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;
    RestClient.Get(`staff/managers`)
      .then((response) => {
        // load a list of staff that are managers
        // console.log(response);
        let managers = [];
        if (response || response.staffList) {
          managers = response.staffList.map((manager) => {
            return {
              value: manager.staffID,
              label: `${manager.firstName} ${manager.lastName}`,
            };
          });
        }
        managers.push({ value: 'Manager Not in List', label: 'Manager Not in List' });

        setManagerList(managers);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [employee, refreshParentComponent]);

  const handleSendRepForm = () => {
    if (
      RepFormValidation(
        repWorkApplication,
        repWorkApplicationError,
        setRepWorkApplicationError,
        tppSettings.isLongHaul,
        isCrystalSki
      )
    ) {
      // add exta fields
      repWorkApplication.repGlobalForm.staffId = employee.staffID;
      repWorkApplication.repGlobalForm.currentSeason = tppSettings.curSeason;
      repWorkApplication.repGlobalForm.nextSeason = tppSettings.nextSeason;
      repWorkApplication.repGlobalForm.signature = currentUserEmail;
      repWorkApplication.isLongHaul = tppSettings.isLongHaul;
      repWorkApplication.isCrystalSki = isCrystalSki;
      dispatch(setLoader(true));
      RestClient.Post(`workApplication/rep/`, repWorkApplication)
        .then((response) => {
          if (!response) return;
          // console.log(response);
          if (response.status) {
            // toastr.success('Success', response.message);
            setRefreshParentComponent(Math.random());
          } else {
            toastr.error('Error', response.message);
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    } else {
      toastr.warning('Form missing data', 'Please fill out the required fields');
      return;
    }
  };

  const cardStyle = {
    marginTop: '0.5%',
  };

  useEffect(() => {
    // console.log(repWorkApplication);
  }, [repWorkApplication]);

  return (
    <>
      <h1 style={{ marginTop: '0.5%' }}>Work Application Form</h1>
      {assigmentsList?.length === 0 && (
        <>
          <hr style={{ marginTop: '1%' }}></hr>

          <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%' }}>
            <h4>
              <i className="fa fa-search" /> Can´t find any assigment for you
            </h4>
          </Row>
          <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%' }}>
            <small>Seems like you can´t apply at the moment. Try later !.</small>
          </Row>
        </>
      )}

      {isWorkApplicationSubmitted && assigmentsList?.length > 0 ? (
        <>
          <Alert
            variant="success"
            style={{
              marginTop: '1%',
            }}
          >
            <Alert.Heading>Thank You !</Alert.Heading>
            <p>
              We´re happy to let you know that we´ve received your application with your placement preferences. Our goal
              is to match you with the best positions that fit your requests, while also considering our business needs.
              You’ll receive communication about Placements once they become available, directly from your Team Manager.
            </p>
            <strong>Your Placement Team</strong>

            <hr />
            <p className="mb-0">
              Your application was received on{' '}
              <strong>{DateTimeFormater(repWorkApplication.repGlobalForm.timestamp, null, DATETIME_FORMAT_WEB)}</strong>{' '}
              and was saved with id: <strong>{repWorkApplication.repGlobalForm.id}</strong>
            </p>
          </Alert>
          {/* <Alert
            variant="warning"
            style={{
              marginTop: '1%',
            }}
          >
            <small>
              5 minutes ? This survey helps us a lot to give you a better service{' '}
              <Button
                size="sm"
                style={{ height: '20px', padding: '0px 5px', fontSize: '11px' }}
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=W_bh43O5DUS2HLyJX8mOKOluEiab5cBNmhKUwoPjX4dUNElQUFAyOVRSTVVLRTVBTUlFTEg0UUJKVS4u"
                target={'_blank'}
              >
                Mtp survey
              </Button>
            </small>
          </Alert> */}
        </>
      ) : (
        <div hidden={assigmentsList?.length === 0}>
          <Alert
            variant="warning"
            style={{
              marginTop: '1%',
            }}
          >
            <Alert.Heading>Remember!</Alert.Heading>
            Please ensure that you accurately fill out the application form, as changes cannot be made once submitted!
          </Alert>
        </div>
      )}
      {!isLoading && assigmentsList?.length > 0 && (
        <>
          {!tppSettings.isLongHaul && !isCrystalSki && (
            <>
              <RepBeachForm
                repWorkApplication={repWorkApplication}
                setRepWorkApplication={setRepWorkApplication}
                repWorkApplicationError={repWorkApplicationError}
                setRepWorkApplicationError={setRepWorkApplicationError}
                isWorkApplicationSubmitted={isWorkApplicationSubmitted}
              />

              <RepWinterForm
                jobFamiliesOptionsList={jobFamiliesOptionsList}
                jobTitlesOptionsList={jobTitlesOptionsList}
                repWorkApplication={repWorkApplication}
                setRepWorkApplication={setRepWorkApplication}
                repWorkApplicationError={repWorkApplicationError}
                setRepWorkApplicationError={setRepWorkApplicationError}
                isWorkApplicationSubmitted={isWorkApplicationSubmitted}
              />

              {repWorkApplication.repWinterForm.preferencesFor !=
                'I do not wish to work in winter and plan to resign at the end of summer' && (
                <RepSummerForm
                  jobFamiliesOptionsList={jobFamiliesOptionsList}
                  jobTitlesOptionsList={jobTitlesOptionsList}
                  repWorkApplication={repWorkApplication}
                  setRepWorkApplication={setRepWorkApplication}
                  repWorkApplicationError={repWorkApplicationError}
                  setRepWorkApplicationError={setRepWorkApplicationError}
                  isWorkApplicationSubmitted={isWorkApplicationSubmitted}
                />
              )}
            </>
          )}

          {tppSettings.isLongHaul && (
            <RepLongHaulForm
              repWorkApplication={repWorkApplication}
              setRepWorkApplication={setRepWorkApplication}
              repWorkApplicationError={repWorkApplicationError}
              isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            />
          )}

          {isCrystalSki && (
            <RepCrystalSkiForm
              repWorkApplication={repWorkApplication}
              setRepWorkApplication={setRepWorkApplication}
              repWorkApplicationError={repWorkApplicationError}
              setRepWorkApplicationError={setRepWorkApplicationError}
              isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            />
          )}

          <Card style={cardStyle}>
            <Card.Header>
              <i className="fa fa-check mr-2" />
              Submit form
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Signature</Form.Label>
                    <Form.Control
                      type="text"
                      value={currentUserEmail}
                      disabled
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repGlobalForm: {
                            ...repWorkApplication.repGlobalForm,
                            signature: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Location{requiredField()}</Form.Label>
                    <Select
                      options={countriesOptionsList}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repGlobalForm: {
                            ...repWorkApplication.repGlobalForm,
                            location: e?.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repGlobalForm: {
                            ...repWorkApplicationError.repGlobalForm,
                            location: '',
                          },
                        });
                      }}
                      value={
                        countriesOptionsList.find((obj) => obj.value === repWorkApplication.repGlobalForm.location) ||
                        null
                      }
                      isClearable
                      isSearchable
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repGlobalForm.location && (
                      <Form.Text className="form-text-red">{repWorkApplicationError.repGlobalForm.location}</Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Manager name {requiredField()}</Form.Label>
                    <Select
                      options={managersList}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repGlobalForm: {
                            ...repWorkApplication.repGlobalForm,
                            managerName: e?.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repGlobalForm: {
                            ...repWorkApplicationError.repGlobalForm,
                            managerName: '',
                          },
                        });
                      }}
                      value={
                        managersList.find((obj) => obj.value === repWorkApplication.repGlobalForm.managerName) || null
                      }
                      isClearable
                      isSearchable
                      isDisabled={isWorkApplicationSubmitted}
                    />

                    {repWorkApplicationError.repGlobalForm.managerName && (
                      <Form.Text className="form-text-red">
                        {repWorkApplicationError.repGlobalForm.managerName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Any other comments ?</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="100 characters max"
                      rows={8}
                      value={repWorkApplication.repGlobalForm.repComments}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repGlobalForm: {
                            ...repWorkApplication.repGlobalForm,
                            repComments: e.target.value,
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                      maxLength={100}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
          <Row style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1%' }}>
            <Button
              variant="success"
              style={{ marginRight: '10px' }}
              onClick={handleSendRepForm}
              size="lg"
              disabled={isWorkApplicationSubmitted}
            >
              Submit
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default RepWorkApplicationView;
