import React from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';

import { DATE_FORMAT_ISO, DATE_NO_DAY_FORMAT_WEB } from 'Constants/dateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const RepCrystalSkiForm = (props) => {
  const {
    repWorkApplication,
    repWorkApplicationError,
    setRepWorkApplicationError,
    setRepWorkApplication,
    isWorkApplicationSubmitted,
  } = props;

  console.log('repWorkApplication', repWorkApplication);

  const roleOptions = [
    { value: 'Hotel Rep', label: 'Hotel Rep' },
    { value: 'Airport Rep', label: 'Airport Rep' },
    { value: 'Service Support Rep', label: 'Service Support Rep' },
    { value: 'Team Leader', label: 'Team Leader' },
    { value: 'Team Manager', label: 'Team Manager' },
    { value: 'Blue Star', label: 'Blue Star' },
  ];

  const cardStyle = {
    marginTop: '1%',
  };
  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  return (
    <>
      <h2 style={cardStyle}>Crystal Ski</h2>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-user mr-2" />
          Fill the required information
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Do you already have a Summer 2025 Placement? {requiredField()}</Form.Label>
                <Form.Check
                  type="radio"
                  label="Yes"
                  name="summer2025Placement"
                  id="summer2025PlacementYes"
                  checked={repWorkApplication.repCrystalSkiForm?.summer2025Placement === 'Yes'}
                  onChange={() => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repCrystalSkiForm: {
                        ...repWorkApplication.repCrystalSkiForm,
                        summer2025Placement: 'Yes',
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repCrystalSkiForm: {
                        ...repWorkApplicationError.repCrystalSkiForm,
                        summer2025Placement: null,
                      },
                    });
                  }}
                  disabled={isWorkApplicationSubmitted}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name="summer2025Placement"
                  id="summer2025PlacementNo"
                  checked={repWorkApplication.repCrystalSkiForm?.summer2025Placement === 'No'}
                  onChange={() => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repCrystalSkiForm: {
                        ...repWorkApplication.repCrystalSkiForm,
                        summer2025Placement: 'No',
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repCrystalSkiForm: {
                        ...repWorkApplicationError.repCrystalSkiForm,
                        summer2025Placement: '',
                      },
                    });
                  }}
                  disabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repCrystalSkiForm.summer2025Placement && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repCrystalSkiForm.summer2025Placement}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>

            {repWorkApplication.repCrystalSkiForm?.summer2025Placement === 'No' ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Are you interested in a Summer 2025 Placement? {requiredField()}</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="interestedInPlacement"
                      id="interestedInPlacementYes"
                      checked={repWorkApplication.repCrystalSkiForm?.interestedInPlacement === 'Yes'}
                      onChange={() => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repCrystalSkiForm: {
                            ...repWorkApplication.repCrystalSkiForm,
                            interestedInPlacement: 'Yes',
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repCrystalSkiForm: {
                            ...repWorkApplicationError.repCrystalSkiForm,
                            interestedInPlacement: '',
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />

                    <Form.Check
                      type="radio"
                      label="No"
                      name="interestedInPlacement"
                      id="interestedInPlacementNo"
                      checked={repWorkApplication.repCrystalSkiForm?.interestedInPlacement === 'No'}
                      onChange={() => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repCrystalSkiForm: {
                            ...repWorkApplication.repCrystalSkiForm,
                            interestedInPlacement: 'No',
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repCrystalSkiForm: {
                            ...repWorkApplicationError.repCrystalSkiForm,
                            interestedInPlacement: '',
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repCrystalSkiForm.interestedInPlacement && (
                      <Form.Text className="form-text-red">
                        {repWorkApplicationError.repCrystalSkiForm.interestedInPlacement}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Form.Row>
                {repWorkApplication.repCrystalSkiForm?.interestedInPlacement === 'Yes' && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} lg={12}>
                        <Form.Label>What Role are you interested in for Summer 2025? {requiredField()}</Form.Label>
                        <Select
                          options={roleOptions}
                          value={roleOptions.find(
                            (option) => option.value === repWorkApplication.repCrystalSkiForm?.roleInterested
                          )}
                          onChange={(selectedOption) => {
                            setRepWorkApplication({
                              ...repWorkApplication,
                              repCrystalSkiForm: {
                                ...repWorkApplication.repCrystalSkiForm,
                                roleInterested: selectedOption.value,
                              },
                            });
                            setRepWorkApplicationError({
                              ...repWorkApplicationError,
                              repCrystalSkiForm: {
                                ...repWorkApplicationError.repCrystalSkiForm,
                                roleInterested: null,
                              },
                            });
                          }}
                          isDisabled={isWorkApplicationSubmitted}
                        />
                        {repWorkApplicationError.repCrystalSkiForm.roleInterested && (
                          <Form.Text className="form-text-red">
                            {repWorkApplicationError.repCrystalSkiForm.roleInterested}
                          </Form.Text>
                        )}
                      </Form.Group>

                      <Form.Group as={Col} lg={6}>
                        <Form.Label>What date are you available from? {requiredField()}</Form.Label>
                        <Datetime
                          dateFormat="YYYY-MM"
                          timeFormat={false}
                          value={DateTimeFormater(
                            repWorkApplication.repCrystalSkiForm?.availableFrom,
                            DATE_FORMAT_ISO,
                            DATE_NO_DAY_FORMAT_WEB
                          )}
                          onChange={(date) => {
                            setRepWorkApplication({
                              ...repWorkApplication,
                              repCrystalSkiForm: {
                                ...repWorkApplication.repCrystalSkiForm,
                                availableFrom: date.format(DATE_FORMAT_ISO),
                              },
                            });
                            setRepWorkApplicationError({
                              ...repWorkApplicationError,
                              repCrystalSkiForm: {
                                ...repWorkApplicationError.repCrystalSkiForm,
                                availableFrom: '',
                              },
                            });
                          }}
                          closeOnSelect
                          inputProps={{ disabled: isWorkApplicationSubmitted }}
                        />
                        {repWorkApplicationError.repCrystalSkiForm.availableFrom && (
                          <Form.Text className="form-text-red">
                            {repWorkApplicationError.repCrystalSkiForm.availableFrom}
                          </Form.Text>
                        )}
                      </Form.Group>

                      <Form.Group as={Col} lg={6}>
                        <Form.Label>What date are you available to? {requiredField()}</Form.Label>
                        <Datetime
                          dateFormat="YYYY-MM"
                          timeFormat={false}
                          value={DateTimeFormater(
                            repWorkApplication.repCrystalSkiForm?.availableTo,
                            DATE_FORMAT_ISO,
                            DATE_NO_DAY_FORMAT_WEB
                          )}
                          onChange={(date) => {
                            setRepWorkApplication({
                              ...repWorkApplication,
                              repCrystalSkiForm: {
                                ...repWorkApplication.repCrystalSkiForm,
                                availableTo: date.format(DATE_FORMAT_ISO),
                              },
                            });
                            setRepWorkApplicationError({
                              ...repWorkApplicationError,
                              repCrystalSkiForm: {
                                ...repWorkApplicationError.repCrystalSkiForm,
                                availableTo: '',
                              },
                            });
                          }}
                          closeOnSelect
                          inputProps={{ disabled: isWorkApplicationSubmitted }}
                        />
                        {repWorkApplicationError.repCrystalSkiForm.availableTo && (
                          <Form.Text className="form-text-red">
                            {repWorkApplicationError.repCrystalSkiForm.availableTo}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} lg={12}>
                        <Form.Label>
                          Why are you interested in a Summer 2025 placement and why do you think you´re suitable?{' '}
                          {requiredField()}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          maxLength={500}
                          value={repWorkApplication.repCrystalSkiForm?.interestReason}
                          onChange={(e) => {
                            setRepWorkApplication({
                              ...repWorkApplication,
                              repCrystalSkiForm: {
                                ...repWorkApplication.repCrystalSkiForm,
                                interestReason: e.target.value,
                              },
                            });
                            setRepWorkApplicationError({
                              ...repWorkApplicationError,
                              repCrystalSkiForm: {
                                ...repWorkApplicationError.repCrystalSkiForm,
                                interestReason: '',
                              },
                            });
                          }}
                          disabled={isWorkApplicationSubmitted}
                        />
                        {repWorkApplicationError.repCrystalSkiForm.interestReason && (
                          <Form.Text className="form-text-red">
                            {repWorkApplicationError.repCrystalSkiForm.interestReason}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Form.Row>
                  </>
                )}

                {repWorkApplication.repCrystalSkiForm?.interestedInPlacement === 'No' && (
                  <>
                    Thank You, no further information is required from you at this time and you are not required to
                    submit the form
                  </>
                )}
              </>
            ) : repWorkApplication.repCrystalSkiForm?.summer2025Placement === 'Yes' ? (
              <>
                Thank You, no further information is required from you at this time and you are not required to submit
                the form
              </>
            ) : (
              <> </>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default RepCrystalSkiForm;
